@import "variables.scss";
@import "theme.scss";
@import "reset.scss";
@import "global.scss";
@import "typography.scss";


// import overrides
#nprogress {
    .bar {
        background: #111;
        opacity: .8;
        height: .25em !important;
        z-index: 10000000;
        position: fixed;
        top:0;
        left:0;
        right:0;
    }

    .peg,
    .spinner {
        display: none !important;
    }
}
body.segment-business #nprogress .bar {
    background-color: white;
    opacity: 1;
}
