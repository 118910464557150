@use 'sass:math';
@import '/assets/styles/utils.scss';
html {
    scroll-behavior: smooth;
}
body {
    font-family: var(--font), Helvetica, sans-serif;
    line-height: 160%;
    background-color: var(--primary-white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

a {
    text-decoration: none;
}

a,
button {
    color: inherit;
}

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
.grecaptcha-badge {
    visibility: hidden;
}

.blur {
    background-color: rgba(#f2f2f2, 0.99);
    @supports (
        (-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))
    ) {
        background-color: rgba(#f2f2f2, 0.7);
        backdrop-filter: blur(50px);
    }
}

.arrow-link {
    &--left,
    &--right {
        --arrow-translate: 0;
        display: inline-flex;
        gap: em(4, 14);
        align-items: center;
        cursor: pointer;
        font-size: 0.874em;
        line-height: 160%;
        font-weight: 500;

        &:hover {
            --arrow-translate: 4px;
        }

        svg {
            transition: transform 160ms ease;
            transform: translateX(var(--arrow-translate));
            width: em(16, 14);
        }
    }
}

.button {
    &--arrow,
    &--back {
        cursor: pointer;
        display: inline-block;
        align-items: center;
        font-size: 0.874em;
        line-height: 160%;
        font-weight: 500;

        > span {
            white-space: nowrap;
        }

        svg {
            width: em(16, 14);
            transform: translateY(0.2em);
        }
    }

    &--arrow {
        svg {
            margin-left: em(4, 14);
        }
    }

    &--back {
        svg {
            margin-right: em(4, 14);
        }
    }

    &--pill {
        $button--pill: &;
        --angle-translate: 0;
        cursor: pointer;
        display: inline-flex;
        padding: em(13, 14) em(24, 14);
        font-size: 0.874em;
        line-height: 160%;
        font-weight: 500;
        min-width: 100px;
        background: linear-gradient(180deg, #00D63C 0%, #80F64D 100%);
        color: var(--text-color);
        transition: background-color 180ms ease;
        // border-radius: em(100, 14);
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        position: relative;
        isolation: isolate;
        overflow: hidden;
        --hover-color: linear-gradient(0deg, #00D63C 0%, #80F64D 100%);

        clip-path: url(#squircle);

        @media (hover: hover) {
            &:after {
                transition: transform 500ms ease;
                content: '';
                inset: -1em;
                position: absolute;
                z-index: -1;
                background: var(--hover-color);
                transform-origin: bottom;
                transform: scaleY(var(--angle-translate));
            }
            &:hover {
                @include button-hover;
            }
        }
        &:disabled {
            background-color: var(--gray-90) !important;
            opacity: 0.5;
            cursor: default;
            &:hover {
                --hover-color: var(--gray-90) !important;
            }
        }


        @at-root .segment-private {
            #{$button--pill} {
                --text-color: black;
            }
        }

        &.button--secondary {
            background: white;
            color: black;
            --hover-color: var(--gray-60);
            // border: 2px solid var(--gray-60);
            background-color: var(--gray-60); // Actual is border color when using Squircle
            &::before {
                background-color: white;
            }
        }

        &.button--dark {
            background: black;
            --text-color: white;
            --hover-color: #2e2e2e;
        }

        &.button--light {
            background: white;
            --text-color: black;
            --hover-color: var(--gray-30);
        }

        svg {
            width: 1.6em;
            height: 1.6em;
            margin: 0 auto;
        }


        // Make buttons on business green
        @at-root .segment-business {
            #{$button--pill} {
                background: var(--primary-green);
                --text-color: white;
                --hover-color: black;
            }

            .bg-black {
                #{$button--pill} {
                    --text-color: white;
                    --hover-color: white;
                    &:hover {
                        --text-color: black
                    }
                }
            }
        }

        // Make secondary buttons on business white
        @at-root .segment-business &.button--secondary {
            background: var(--gray-60);
            &::before {
                background-color: white;
            }
            color: black;
            --hover-color: black;
            &:hover {
                border-color: black;
                color: white;
            }
        }

        // If black background, make button white
        @at-root .bg-black & {
            // --hover-color: white;
            // &:hover {
            //     color: black;
            // }

            &.button--secondary {
                background: white;
                color: white;
                &::before {
                    background-color: black;
                }
                --hover-color: white;
                &:hover {
                    border-color: white;
                    color: black;
                }
            }

            .arrow-link--right.button--light {
                color: var(--primary-orange);
            }
        }

        @at-root .bg-primary-green &,
            .bg-primary-green &.button--light {
            background: black;
            color: white;
            --hover-color: white;
            @media (hover: hover) {
                &:hover {
                    color: black;
                }
            }
        }
    }

    &--center {
        justify-content: center;
    }

    &--close {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: em(40);
        height: em(40);
        background-color: var(--marine);
        border-radius: 50%;
        color: var(--primary-white);

        &:hover {
            box-shadow: inset 0 10em rgba(#000, 0.1);
            color: var(--primary-white) !important;
        }

        svg {
            width: em(16);
            display: block;
        }
    }
}

input[type='checkbox'], input[type='radio'] {
    border-radius: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid var(--gray-60);
    transition: background-color 180ms ease;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    cursor: pointer;
    background-color: var(--primary-white);
    &:checked {
        background-color: var(--accent);
        border-color: var(--accent);
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0.5em;
            height: 0.5em;
            background-color: var(--primary-white);
            border-radius: 100%;
        }
    }
}

.center-content {
    display: flex;
    justify-content: center;
}
.hide {
    display: none;
}
.hide-on-mobile {
    @media (max-width: 767px) {
        display: none !important;
    }
}
.hide-on-desktop {
    @media (min-width: 768px) {
        display: none !important;
    }
}

.mb-small {
    margin-bottom: 12px;
}
.mb-medium {
    margin-bottom: 18px;
}
.mb-large {
    margin-bottom: 24px;
}
.mb-xlarge {
    margin-bottom: 34px;
}

body {
    // Margin on the sides
    --margin-spacing: 16px;
    // Spacing between modules
    --module-spacing: 4em;

    @media (min-width: 768px) {
        --margin-spacing: 2em;
        --module-spacing: 5em;
    }
    @media (min-width: 1024px) {
        --margin-spacing: 2.5em;
        --module-spacing: 6em;
    }
}
canvas {
    border-radius: 8px;
}
.has-background-color {
    padding: calc(0.6 * var(--module-spacing)) 0;
    // @media (min-width: 768px) {
    //     padding: 80px 0;
    // }
}
[class*='--collapse-margin-top'] {
    .has-background-color {
        // padding-top: calc(0.6 * var(--module-spacing));
    }
}
[class*='--collapse-margin-bottom'] {
    .has-background-color {
        // padding-bottom: calc(0.6 * var(--module-spacing));
    }
}

.bg-accent {
    background-color: var(--accent);
}
.colored {
    color: var(--accent);
}
.segment-private .colored {
    background: linear-gradient(180deg, #00d63c, #80f64d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.colored svg.icon path {
    fill: url(#businessColoredSvgGradient)
}
.segment-private {
    --accent: var(--primary-green);
    .bg-accent {
        background: linear-gradient(180deg, #00D63C 0%, #80F64D 100%);
    }
}
.segment-business {
    --accent: var(--primary-green);
    background-color: var(--fog);
    .bg-accent {
        background: linear-gradient(180deg, #00D63C 0%, #80F64D 100%);
    }
}
.bg-primary-green {
    background: linear-gradient(180deg, #00D63C 0%, #80F64D 100%);
}

.shareit-theme {
    --sky: #406be9;

    .button--pill {
        background-color: black;
    }
    [class*='FooterTopBlock_footer-top-block__inner'] {
        color: white;

        [class*='SendSms_send-sms__input__pre-fix'] {
            color: var(--sky);
        }
        .button--pill {
            background-color: var(--sky);
        }
    }

    [class*='UspModule_usp-module'] {
        [class*='UspModule_usp-module__item'] {
            div {
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-color: inherit;
                background-size: contain;
                border-radius: 0;
            }
            &:nth-child(1) div {
                background-size: 75%;
                background-image: url(https://cdn.sanity.io/images/inp43svr/production/8925dd9d42bf68599329f2acb01310acd0eee1c1-52x62.svg);
            }
            &:nth-child(2) div {
                background-image: url(https://cdn.sanity.io/images/inp43svr/production/56d17df2b6670f0953ed7e01e10053a0cdc990ad-69x69.svg);
            }
            &:nth-child(3) div {
                background-image: url(https://cdn.sanity.io/images/inp43svr/production/89567d4abf0df10525f16e2de65461a23cf4784c-42x76.svg);
            }
        }
    }
    [class*='CollageDefaultModule_collage-default-module__media-wrapper'] {
        // margin-top: 0;
    }

    main {
        // .button--pill.label-100 {
        //     font-style: normal;
        //     font-weight: 500;
        //     font-size: 1.25em;
        //     line-height: 160%;
        // }
    }
}

.segment-moonrise  {
    font-family: "Gabarito", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    background-color: white;

    --accent: #9E45F7;
    --fog: #D5D3CF;
    --night: #201B25;
    --coral: #EAE1EC;

    .button--pill {
        background: var(--accent);
        font-size: 1em;
        padding: 0.5em 1em
    }
    .bg-night {
        background-color: var(--night);
        color: white;
    }
    .heading-100, .heading-200, .heading-300, .heading-400 {
        font-weight: 500;
    }
    .paragraph-100, .paragraph-200 {
        font-size: 20px;
    }

    // Blur background in header
    header[class*="Header_header"] {
        background-color: transparent !important;
        border-bottom: 0;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: rgba(0, 0, 0, 0.30);
            background: color(display-p3 0 0 0 / 0.30);
            backdrop-filter: blur(20px);
        }
    }
    nav {
        justify-content: space-between;
        .label-100 {
            font-size: 1em;
        }
        [class*="Header_header__menu__item--active"] {
            a:after, > div:after {
                z-index: 100;
                height: 2px;
                border-radius: 2px;
                background: white;
                box-shadow: 0px 0px 8px 4px #AB3EFF;
            }
        }


        [class*="Menu_menu__popup--active"] {
            color: white;
            border-radius: 0px 0px 16px 16px;
            background: rgba(0, 0, 0, 0.90);
            backdrop-filter: blur(20px);
        }
        [class*="Menu_menu__popup__sublist__item__media"] {
            background: rgba(255, 255, 255, 0.15);
        }
        [class*="Menu_menu__popup__sublist__item__subtitle"] {
            font-size: 0.875em;
        }
        @media (hover: hover) {
            [class*="Menu_menu__popup__sublist__item"]:hover {
                background-color: transparent;
            }
        }
    }
    // Center the navigation in the header
    [class*="Header_header__nav"] {
        margin: 0 0;
    }
    [class*="Header_header__right-side"] {
        margin-left: 0;
    }
    [class*="DownloadOverlay_download-overlay"] {
        label {
            display: none;
        }
    }
    [class*="SegmentSelector_segment-selector"],
    [class*="LanguageSelector_language-selector"] {
        visibility: hidden;
    }

    // Remove the extra-height on mobile, that we have on other segments
    [class*="CollageModule_collage-module--is-hero"][class*="CollageModule_collage-module--fill-height"] {
        @media (max-width: 767px) {
            min-height: calc(90vh - var(--header-height));
        }
    }
    // Adjust max width for text
    [class*="CollageModule_collage-module__box--narrow"] {
        h1, h2, h3, h4, .paragraph-200, .paragraph-300, .paragraph-600 {
            max-width: 56ch;
        }
    }
    [class*="CollageModule_collage-module__box__title"] p {
        white-space: pre-wrap;
    }
    &:not([class*="news"]) [class*="CollageModule_collage-module__box__sub-title"] {
        font-size: 1.5rem;
        color: var(--accent);
        opacity: 1;
        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }
    [class*="TextGridModule_text-grid-module__list--columns-3"] {
        @media (min-width: 768px) {
            max-width: 1200px;
            column-gap: 4.2em;
            margin-left: auto;
            margin-right: auto;
        }
    }
    // Scrolling anchor navigation via text grid module in position 2
    [data-module-name="textGridModule"]:nth-child(2) {
        [class*="TextGridModule_text-grid-module__item__boxed-wrapper"] {
            border-radius: 16px;
            justify-content: space-between;
            background: radial-gradient(
                80.97% 100% at 50% 0%,
                rgba(171, 62, 255, 0.2) 0%,
                rgba(171, 62, 255, 0) 100%
              ),
            #221B27;
            box-shadow:
                0px 0px 0px 0px rgba(0, 0, 0, 1),
                0px 1px 0px 0px rgba(171, 62, 255, 0.5) inset,
                0px 0px 0px 0px rgba(171, 62, 255, 0.5);
            transition: all 0.2s ease;

            &:hover {
              background: radial-gradient(
                  80.97% 100% at 50% 0%,
                  rgba(171, 62, 255, 0.35) 0%,
                  rgba(171, 62, 255, 0) 100%
                ),
                #221B27;
              box-shadow:
                0px 8px 16px 0px rgba(0, 0, 0, 1),
                0px 1px 1px 0px rgba(171, 62, 255, 1) inset,
                0px 8px 64px 0px rgba(171, 62, 255, 0.5);
            }
          }
        [class*="TextGridModule_text-grid-module__item-media"] {
            flex-grow: 0;
        }
        [class*="TextGridModule_text-grid-module__item-text"] {
            margin-bottom: 24px;
            background: linear-gradient(to bottom, #A47CCD 0%, #9E46F7 100%);
            -webkit-background-clip: transparent;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: white;
            filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.4));
            > * {
                font-size: 1.2em;
                @media (min-width: 767px) {
                    font-size: 1.75em;
                }
            }
        }
    }
    [class*="RichText_rich-text"] ul li:has(svg) {
        padding-left: 58px;
        min-height: 42px;
        > svg:first-child {
            width: 40px;
            height: 40px;
        }
    }

    // Frontpage
    &.path- {
        [data-module-name="collageModule"]:nth-child(3) {
            [class*="CollageModule_collage-module__box__title"] {
                @media (min-width: 767px) {
                    margin-top: 80px;
                }
                background: linear-gradient(to bottom, #ffffff 0%, #CFA2FB 100%);
                -webkit-background-clip: transparent;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: white;
            }
        }
        [data-module-name="testimonialsModule"]:nth-child(2) {
            color: white;
            filter: invert(1);
            opacity: 0.7;
            height: 0px;
            overflow: visible;
            position: relative;
            z-index: 10;

            > * {
                overflow: hidden;
                transform: translate(0, -100%);
            }

            [class*="Container_container"] {
                max-width: none;
                width: 100%;
            }
            [class*="TestimonialsModule_testimonials__ratings-outer--scroll"] {
                max-width: none;
                [class*="TestimonialsModule_testimonials__ratings"] {
                    animation-duration: 25s;
                }

            }

        }
    }
    // To align with video background color in third module, so they blend together
    &.path-about {
        --coral: #eee5ef;

        [id="module-1"] [class*="CollageModule_collage-module__background-media-wrapper"]:after {
            content: "";
            background: linear-gradient(0deg, var(--coral) 0%, #ece1ed00 100%);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100px;
        }
    }
    &.path-news {
        .paragraph-100, .paragraph-200 {
            font-size: 0.874em;
        }
        [class*="CollageModule_collage-module__box__media-wrapper-position-background-fill"],
        [class*="CollageModule_collage-module__background-media-wrapper"] {
            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: black;
                opacity: 0.2;
            }
        }

    }

}

// this is needed for ios that colors tabs blue by default??!
[role='tab'] {
    color: inherit;
}

// for exoctic buttons LinkHandler
[role='button'] {
    cursor: pointer;
}

.path-dkprivatrabatter-telmore {
    [class*='TextGridModule_text-grid-module__list__'] {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
}

.path-dkprivatjobs-and-career,
.path-enpersonaljobs-and-career,
.path-seprivatjobs-and-career,
.path-noprivatjobs-and-career,
.path-enpersonalour-mission,
.path-enpersonallunar-ai,
.path-dkprivatlunar-ai,
.path-seprivatlunar-ai {
    --accent: var(--primary-green);

    .button--pill {
        background-color: var(--accent);
    }
}
.path-dkprivatai, .path-enpersonalai, .path-seprivatai {
    .button--pill {
        background-color: black;
        color: white;
    }
    @media (max-width: 768px) {
        [class*="CollageModule_collage-module--square-format"] {
            height: calc(100vw / (387/800));
            min-height: auto;
        }
    }
    @media (min-width: 767px) {
        [class*="CollageModule_collage-module--square-format"] {
            height: calc(100vw /(16 / 9));
            min-height: auto;
        }
        [class*="CollageModule_collage-module--square-format"] [class*="CollageModule_collage-module__box-wrapper__8cX4_"] {
            min-height: auto;
        }

    }
}

// Kids
.path-enpersonalaccount-and-card-for-kids-and-teens,
.path-dkprivatkonto-og-kort-til-boern-og-unge,
.path-privatkonto-och-kort-foer-barn-och-unga,
.path-noprivatkonto-og-kort-for-barn-og-unge {
    --kids-orange: #FF7A22;
    .colored {
        --accent: var(--kids-orange);
        background: none;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        background-clip: unset;
        text-fill-color: unset;
    }

    [class*="RichText_rich-text__"] ul li:not(:has(svg))::before {
        background-image: url('data:image/svg+xml,<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_530_7038)"><circle cx="12" cy="12.5" r="12" fill="%23FF7A22"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M19 9.70526L12.5922 16.7501C12.3759 16.9878 12.1192 17.1764 11.8365 17.3051C11.5538 17.4338 11.2509 17.5 10.9449 17.5C10.6389 17.5 10.3359 17.4338 10.0532 17.3051C9.77076 17.1765 9.51412 16.988 9.29797 16.7505L6 13.1372L7.09631 11.9304L10.3958 15.5453C10.4679 15.6246 10.5534 15.6875 10.6477 15.7304C10.7419 15.7732 10.8429 15.7953 10.9449 15.7953C11.0469 15.7953 11.1479 15.7732 11.2421 15.7304C11.3363 15.6875 11.4219 15.6246 11.494 15.5453L17.9024 8.49987L19 9.70526Z" fill="white"/><defs><clipPath id="clip0_530_7038"><rect width="24" height="24" fill="white" transform="translate(0 0.5)"/></clipPath></defs></svg>')
    }

    #module-2[data-module-name="textGridModule"], #module-3[data-module-name="textGridModule"] {

        [class*="TextGridModule_text-grid-module__item__boxed-wrapper"]:last-child {
            color: white;
        }
    }

}


// 1 million users ribbon on frontpages
.doc-frontpage {
    [data-module-name="ribbonModule"]:nth-child(2) {
        > div {
            background-image: url('https://cdn.sanity.io/images/inp43svr/production/2c2bb96433d9f736d66c2e8876d97dce959574e8-4808x3205.jpg?w=1800&h=100&max-h=2000&q=90&auto=format&fit=crop');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            overflow: visible;

            @media (max-width: 768px) {
                [class*="RibbonModule_ribbon-module__static__inner"] {
                    max-width: 80%;
                }
            }

            &:after {
                content: "";
                display: inline-block;
                width: 132px;
                height: 132px;
                background-image: url('https://cdn.sanity.io/images/inp43svr/production/6c3f9c9972b7cf9adce680da51e6d8ff412f4c2a-2292x2165.png?w=150&h=150&format=auto&q=90');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                bottom: 0px;
                right: 0px;
                z-index: 1;
            }
        }
    }
}
