:root {
    --header-height: 81px;
    @media (max-width: 768px) {
        --header-height: 64px;
    }
    --success: #3BBB80;
    --error: #FE5A50;
    --primary-black: #000;
    --primary-white: #fff;
    --dark: #181818;
    --white-alpha-7: rgba(#fff, 0.7);

    --primary-blue: #3C67F4;
    --primary-blue--dark: #214CDB;
    --primary-green: #00CB39;
    --primary-orange: #FF5B00;
    --paas-blue: #00187E;
    --gray-30: #F2F2F2;
    --gray-60: #DCDCDC;
    --gray-90: #969696;
    --gray-100: #6F6F6F;

    --fog: #f2f2f2;
    --shark: #c4c4c4;
    --cloud: #808080;
    --charcoal: #4D4D4D;
    --sky: #64C4FF;
    --sky-80: #64c4ff;
    --sky-60: #a0d9fd;
    --sky-40: #bde3fb;
    --sky-20: #dbeefa;
    --sky-10: #eaf3fa;
    --marine: #0000f9;
    --marine-80: #3232f9;
    --marine-60: #6463f9;
    --marine-40: #9595f9;
    --marine-20: #c7c6f9;
    --marine-10: #e0dff9;
    --night: #0A1E63;
    --night-80: #323275;
    --night-60: #646396;
    --night-40: #9595b7;
    --night-20: #c7c6d8;
    --night-10: #e0dfe9;
    --pearl: #f4d5d3;
    --pearl-80: #f5dcdb;
    --pearl-60: #f6e3e2;
    --pearl-40: #f7eaea;
    --pearl-20: #f8f1f1;
    --pearl-10: #f9f5f5;
    --coral: #FE5A50;
    --coral-80: #f5a5a5;
    --coral-60: #f6baba;
    --coral-40: #f7cecf;
    --coral-20: #f8e3e4;
    --coral-10: #f9eeef;
    --maroon: #450f0c;
    --maroon-80: #693e3b;
    --maroon-60: #8d6c6b;
    --maroon-40: #b19b9a;
    --maroon-20: #d5c9ca;
    --maroon-10: #e7e1e1;
    --mint: #82E6B4;
    --mint-80: #8ed5b4;
    --mint-60: #a9dec5;
    --mint-40: #c3e6d7;
    --mint-20: #deefe8;
    --mint-10: #ecf4f0;
    --pine: #004137;
    --pine-80: #326f67;
    --pine-60: #64928c;
    --pine-40: #95b4b0;
    --pine-20: #c7d6d5;
    --pine-10: #e0e7e7;
    --moss: #00332c;
    --moss-80: #405a55;
    --moss-60: #6f817e;
    --moss-40: #9da9a7;
    --moss-20: #cbd0d0;
    --moss-10: #e2e4e5;
    --font: "SharpGrotesk"; // base font
    --small-card-size: 20.3125em; // 325px only used for testimonials card
}
