a {
    color: inherit;
}

a,
select,
button {
    cursor: pointer;
}

iframe {
    display: block;
    width: 100%;
}

* {
    margin: 0;
    padding:0;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    font-weight: inherit;
    list-style: none;
    border-radius: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    background-color: transparent;
    border: none;
}

body:not(.user-is-tabbing) :focus {
    outline: none;
}

input:not([type="radio"], [type="checkbox"]) {
    -webkit-appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-search-cancel-button{
    display: none !important;
}

body {
    cursor: default;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

legend {
    display: table;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;

    + * {
        clear: both;
    }
}

fieldset {
    min-width: 0;
}


table {
    border: none;
}
