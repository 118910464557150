$primary-colors: (
    "white": var(--primary-white),
    "fog": var(--fog),
    "shark": var(--shark),
    "cloud": var(--cloud),
    "charcoal": var(--charcoal),
    "black": var(--primary-black),
    "primary-blue": var(--primary-blue),
    "primary-blue--dark": var(--primary-blue--dark),
    "paas-blue": var(--paas-blue),
    "primary-green": var(--primary-green),
    "primary-orange": var(--primary-orange),
    "gray-30": var(--gray-30),
    "gray-60": var(--gray-60),
    "gray-90": var(--gray-90),
    "gray-100": var(--gray-100),

    "dark": var(--dark),
    "sky": var(--primary-blue),
    "sky-80": var(--primary-blue),
    "sky-60": var(--primary-blue),
    "sky-40": var(--primary-blue),
    "sky-20": var(--primary-blue),
    "sky-10": var(--primary-blue),
    "marine": var(--primary-blue),
    "marine-80": var(--primary-blue),
    "marine-60": var(--primary-blue),
    "marine-40": var(--primary-blue),
    "marine-20": var(--primary-blue),
    "marine-10": var(--primary-blue),
    "night": var(--primary-blue),
    "night-80": var(--primary-blue),
    "night-60": var(--primary-blue),
    "night-40": var(--primary-blue),
    "night-20": var(--primary-blue),
    "night-10": var(--primary-blue),
    "pearl": var(--pearl),
    "pearl-80": var(--pearl-80),
    "pearl-60": var(--pearl-60),
    "pearl-40": var(--pearl-40),
    "pearl-20": var(--pearl-20),
    "pearl-10": var(--pearl-10),
    "coral": var(--coral),
    "coral-80": var(--coral-80),
    "coral-60": var(--coral-60),
    "coral-40": var(--coral-40),
    "coral-20": var(--coral-20),
    "coral-10": var(--coral-10),
    "maroon": var(--maroon),
    "maroon-80": var(--maroon-80),
    "maroon-60": var(--maroon-60),
    "maroon-40": var(--maroon-40),
    "maroon-20": var(--maroon-20),
    "maroon-10": var(--maroon-10),
    "mint": var(--primary-green),
    "mint-80": var(--primary-green),
    "mint-60": var(--primary-green),
    "mint-40": var(--primary-green),
    "mint-20": var(--primary-green),
    "mint-10": var(--primary-green),
    "pine": var(--primary-blue),
    "pine-80": var(--primary-blue),
    "pine-60": var(--primary-blue),
    "pine-40": var(--primary-blue),
    "pine-20": var(--primary-blue),
    "pine-10": var(--primary-blue),
    "moss": var(--primary-green),
    "moss-80": var(--primary-green),
    "moss-60": var(--primary-green),
    "moss-40": var(--primary-green),
    "moss-20": var(--primary-green),
    "moss-10": var(--primary-green),
);

@mixin gen-props($prefix, $property, $colors) {
    @each $color-name, $color in $colors {
        .#{$prefix}-#{$color-name} {
            #{$property}: $color;
        }
    }
}

@include gen-props("text", "color", $primary-colors);
@include gen-props("bg", "background-color", $primary-colors);
@include gen-props("fill", "fill", $primary-colors);
@include gen-props("contrast-stroke", "stroke",  $primary-colors);
